import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const IndexPage = () => (
  <Layout>
    <SEO />
   
  </Layout>
)

export default IndexPage
